import * as React from 'react';
import { StepperFormProps } from 'components/stepper/stepperTypes';
import { QuestionnaireActivity } from 'models/inputModels/QuestionnaireActivity';
import { ButtonRowRight, Headline4 } from 'components/Elements';
import strings from '../../../../strings/strings.json';
import TextField from 'components/forms/TextField';
import { createErrorMessage, fieldErrorCodes, requiredFieldErrorMessage } from 'shared/error-messages';
import useNeveForm from 'components/forms/NeveForm';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { TextAreaField } from 'components/forms/TextAreaField';
import styled from 'styled-components';
import { sizes } from '../../../../theme';
import { validateThreeCharacterLimit } from 'lib/custom-form-validation';
import useFormBlocker from 'components/forms/useFormBlocker';
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import Alert from 'components/alert/Alert';
import useDeleteRequest from 'hooks/useDeleteRequest';
import { logError } from 'lib/debug-helpers';
import { publish } from 'lib/events';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import DeleteActivityButton from '../taskContentUpdateForms/sharedUpdateComponents/DeleteActivityButton';

type QuestionnaireDetailInput = {
    title: string;
    description: string;
    durationInMins: number | null;
};

export default function QuestionnaireDetailsForm(props: StepperFormProps<QuestionnaireActivity>): JSX.Element {
    const { next, newObject, setNewObject, complete } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useNeveForm<QuestionnaireDetailInput>({
        title: newObject.title ?? '',
        description: newObject.description ?? '',
        durationInMins: newObject.durationInMins ?? null,
    });

    async function onSubmit(formData: QuestionnaireDetailInput) {
        setNewObject({
            ...newObject,
            title: formData.title,
            description: formData.description,
            durationInMins: formData.durationInMins ? +formData.durationInMins : null,
        });

        reset(formData);

        next();
    }

    return (
        <FormBase
            handleSubmit={handleSubmit(onSubmit)}
            register={register}
            errors={errors}
            watch={watch}
            complete={complete}
            task={newObject}
        />
    );
}

export function EditQuestionnaireDetailsForm(props: StepperFormProps<QuestionnaireActivity>): JSX.Element {
    const { next, newObject, setNewObject, complete } = props;

    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        watch,
    } = useNeveForm<QuestionnaireDetailInput>({
        title: newObject.title ?? '',
        description: newObject.description ?? '',
        durationInMins: newObject.durationInMins ?? null,
    });

    const [formDirty, setFormDirty] = React.useState<boolean>(false);

    useFormBlocker(formDirty, strings.courseEditorPage.unsavedChangesPrompt);

    const title = watch('title');
    const description = watch('description');
    const durationInMins = watch('durationInMins');

    React.useEffect(() => {
        const titleChanged = title !== newObject.title;
        const descriptionChanged = description !== newObject.description;
        const durationInMinsChanged = durationInMins != newObject.durationInMins; // eslint-disable-line eqeqeq

        setFormDirty(titleChanged || descriptionChanged || durationInMinsChanged);
    }, [title, description, durationInMins]); // eslint-disable-line

    async function onSubmit(formData: QuestionnaireDetailInput) {
        setNewObject({
            ...newObject,
            title: formData.title,
            description: formData.description,
            durationInMins: formData.durationInMins ? +formData.durationInMins : null,
        });

        reset(formData);

        next();
    }

    return (
        <FormBase
            handleSubmit={handleSubmit(onSubmit)}
            register={register}
            errors={errors}
            watch={watch}
            complete={complete}
            task={newObject}
            editForm
        />
    );
}

type FormBaseProps = {
    handleSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
    register: UseFormRegister<QuestionnaireDetailInput>;
    errors: FieldErrors<QuestionnaireDetailInput>;
    watch: UseFormWatch<QuestionnaireDetailInput>;
    task: QuestionnaireActivity;
    complete?: () => void | undefined;
    editForm?: boolean;
};

function FormBase({
    handleSubmit,
    register,
    errors,
    watch,
    complete: returnToCourseStructure,
    task,
    editForm,
}: FormBaseProps): JSX.Element {
    const context = React.useContext(EditCourseContext);
    const [alertOpen, setAlertOpen] = React.useState(false);

    const titleMaxLength = 100;
    const descriptionMaxLength = 500;

    const { deleteData, deleteInProgress } = useDeleteRequest(`activities/${task.id}`);

    async function onDelete() {
        const response = await deleteData();

        if (response.errors) {
            logError(response.errors);
            return;
        }

        publish('ACTIVITY_DELETED', { id: task.id });

        await context.refetchCourse();
        returnToCourseStructure?.();
        setAlertOpen(false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <HeadlineWithPadding>{strings.questionnaireDetailsForm.instruction}</HeadlineWithPadding>
            <TextField
                fieldName="title"
                maxLength={titleMaxLength}
                labelText={strings.questionnaireDetailsForm.titleInputLabel}
                inputAria={strings.questionnaireDetailsForm.titleInputAria}
                inputProps={register('title', {
                    required: {
                        value: true,
                        message: requiredFieldErrorMessage(strings.questionnaireDetailsForm.titleInputLabel),
                    },
                    maxLength: {
                        value: titleMaxLength,
                        message: createErrorMessage(
                            strings.questionnaireDetailsForm.titleInputLabel,
                            fieldErrorCodes.maxLength,
                        ),
                    },
                })}
                errorMessage={errors.title?.message}
                required
            />
            <TextAreaField
                maxLength={descriptionMaxLength}
                charactersLeft={descriptionMaxLength - watch('description').length}
                fieldName={strings.questionnaireDetailsForm.descriptionInputLabel}
                aria={strings.questionnaireDetailsForm.descriptionInputLabelAria}
                errorMessage={errors.description?.message}
                inputProps={register('description', {
                    maxLength: {
                        value: descriptionMaxLength,
                        message: createErrorMessage(
                            strings.questionnaireDetailsForm.descriptionInputLabel,
                            fieldErrorCodes.maxLength,
                        ),
                    },
                })}
            />
            <TextField
                fieldName="activityTaskDuration"
                maxLength={3}
                labelText={strings.questionnaireDetailsForm.durationInputLabel}
                inputAria={strings.questionnaireDetailsForm.durationInputAria}
                inputProps={register('durationInMins', {
                    validate: {
                        validateThreeCharacterLimit,
                    },
                })}
                errorMessage={errors.durationInMins?.message}
                inputType="number"
                width="8rem"
                flavourText={strings.questionnaireDetailsForm.durationInputMins}
            />
            <ButtonRowRightWithPadding>
                {editForm && <DeleteActivityButton setAlertOpen={setAlertOpen} />}
                <PrimaryButton
                    title={strings.questionnaireDetailsForm.submitButton}
                    aria={strings.questionnaireDetailsForm.submitButtonAria}
                    type="submit"
                />
            </ButtonRowRightWithPadding>
            {alertOpen && (
                <Alert
                    buttonText={strings.courseContentPage.alertButton}
                    ariaButtonText={strings.courseContentPage.alertButtonAria}
                    alertText={strings.courseContentPage.alertText}
                    closeAlert={() => setAlertOpen(false)}
                    onSubmit={onDelete}
                    submitting={deleteInProgress}
                />
            )}
        </form>
    );
}

const ButtonRowRightWithPadding = styled(ButtonRowRight)`
    padding-top: ${sizes.spacingSm};
`;

const HeadlineWithPadding = styled(Headline4)`
    padding-bottom: ${sizes.spacingLg};
`;
