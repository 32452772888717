import { IconSize } from 'assets/icons/icon-sizes';
import { BodySmall, TertiaryButton, WrappingText } from 'components/Elements';
import useModifyRequest from 'hooks/useModifyRequest';
import { NotificationModel, NotificationType } from 'models/Notification';
import styled from 'styled-components';
import { theme, sizes, FONTSIZE } from 'theme';
import strings from '../../strings/strings.json';
import NotificationIcon from 'assets/icons/navigation/NotificationIcon';
import { StepId } from 'pages/edit-course/CourseEditorPage';

type NotificationContentProps = {
    notification: NotificationModel;
    disableButton: boolean;
    navigate: Function;
};

type NotificationsProps = {
    notifications: NotificationModel[];
    disableButtons: boolean;
    navigate: Function;
};

export function Notifications({ notifications, disableButtons, navigate }: NotificationsProps): JSX.Element {
    const orderedNotifications = notifications.sort((a, b) => {
        return a.dateCreated > b.dateCreated ? -1 : 1;
    });

    return (
        <>
            {orderedNotifications.map((notification, index) => {
                return (
                    <NotificationContent
                        key={index}
                        notification={notification}
                        disableButton={disableButtons}
                        navigate={navigate}
                    />
                );
            })}
        </>
    );
}

function NotificationContent({ notification, disableButton, navigate }: NotificationContentProps): JSX.Element {
    const { modifyData: acknowledgeNotification, loading: acknowledgeLoading } = useModifyRequest(
        `users/me/notifications/acknowledge`,
        'POST',
    );

    return (
        <NotificationContainer>
            <InfoRow>
                <NotificationIconContainer>
                    <NotificationIcon colour={theme.navigationBar.buttonText} />
                </NotificationIconContainer>
                <InfoColumn>
                    <MessageAndViewItemRow>
                        <WrappingText>{notification.message}</WrappingText>
                    </MessageAndViewItemRow>
                    <ViewItemButton
                        onClick={async () => {
                            if (!notification.acknowledged) {
                                await acknowledgeNotification({ id: notification.id });
                            }

                            if (notification.type === NotificationType.MODULE_QUESTIONNAIRE_GENERATED) {
                                navigate(notification.link, {
                                    state: { stepId: 'courseAIGeneratedContent' as StepId },
                                });
                            } else {
                                if (
                                    notification.message ===
                                    `Please take a moment to configure your accessibility requirements in your profile section.`
                                ) {
                                    navigate(notification.link, { state: { activeTabId: 'accessibility' } });
                                } else if (notification.message.includes('content has been generated')) {
                                    navigate(notification.link, {
                                        state: { stepId: 'courseAIGeneratedContent' as StepId },
                                    });
                                } else {
                                    navigate(notification.link);
                                }
                            }
                        }}
                        disabled={disableButton || acknowledgeLoading}
                    >
                        {strings.notificationsPage.viewNotificationLink}
                    </ViewItemButton>
                    <BodySmall>{timeElapsedFromDate(notification.dateCreated)}</BodySmall>
                </InfoColumn>
            </InfoRow>

            {!notification.acknowledged ? <RedCircle /> : <TransparentCircle />}
        </NotificationContainer>
    );
}

const NotificationIconContainer = styled.div`
    min-width: ${IconSize.MEDIUM}px;
`;

const NotificationContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: ${theme.cardBackground};
    padding: ${sizes.spacingMd};
    border-top: 1px solid ${theme.cardBorder};
`;

const MessageAndViewItemRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${sizes.spacingSm};
    overflow-wrap: break-all;
`;

const InfoRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${sizes.spacingMd};
`;

const InfoColumn = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    align-items: start;
`;

const ViewItemButton = styled(TertiaryButton)`
    font-size: ${FONTSIZE.BodySmall};
    font-weight: bold;
    color: ${theme.linkColour};
`;

const Circle = styled.div`
    width: ${sizes.spacingMd};
    height: ${sizes.spacingMd};
    border-radius: 50%;
    min-width: ${IconSize.SMALL}px;
`;

const RedCircle = styled(Circle)`
    background-color: ${theme.errorColour};
`;

const TransparentCircle = styled(Circle)`
    background-color: transparent;
`;

function timeElapsedFromDate(date: Date): string {
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    const now = new Date().getTime();
    const elapsed = now - new Date(date).getTime();

    if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' seconds ago';
    } else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' minutes ago';
    } else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' hours ago';
    } else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + ' days ago';
    } else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + ' months ago';
    } else {
        return Math.round(elapsed / msPerYear) + ' years ago';
    }
}
