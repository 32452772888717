import styled from 'styled-components';
import { sizes, breakpoints } from 'theme';

export const ButtonAndNotificationContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: ${sizes.spacingMd};
    width: 100%;
    min-height: 72px;
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column-reverse;
        align-items: unset;
    }
`;
