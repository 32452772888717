import Alert, { AlertError } from 'components/alert/Alert';
import NavBackButton from 'components/buttons/NavBackButton';
import { Headline4, BodyLarge } from 'components/Elements';
import useNeveForm from 'components/forms/NeveForm';
import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import useDeleteRequest from 'hooks/useDeleteRequest';
import useModifyRequest from 'hooks/useModifyRequest';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import React from 'react';
import { breakpoints, sizes, theme } from 'theme';
import { GenerateTypeContentComponent } from '../GenerateTypeContentComponent';
import strings from '../../../../../strings/strings.json';
import Hint from 'components/Hint';
import { createErrorMessage, fieldErrorCodes, requiredFieldErrorMessage } from 'shared/error-messages';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import BinIcon from 'assets/icons/controls/BinIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import {
    ReviewGeneratedContentProps,
    Container,
    ContentCard,
    ApproveFormCard,
    BinIconContainer,
    ErrorRow,
} from './ReviewGeneratedSharedComponents';
import styled from 'styled-components';
import { HelperTextHintAndTextField, QuestionCard } from '../../questionnaire/QuestionnaireQuestionsForm';
import TextField from 'components/forms/TextField';

export default function ReviewGeneratedModuleQuestionnaire({
    item,
    backButton,
}: ReviewGeneratedContentProps): JSX.Element {
    const context = React.useContext(EditCourseContext);
    const [showError, setShowError] = React.useState<boolean>();
    const [openRejectAlert, setOpenRejectAlert] = React.useState(false);
    const [alertError, setAlertError] = React.useState<AlertError>();
    const [getQuestionnaireError, setGetQuestionnaireError] = React.useState<boolean>(false);

    const {
        register,
        unregister,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useNeveForm<GeneratedQuestionnaire>({
        questions: [],
    });
    const questions = watch('questions');

    React.useEffect(() => {
        const getQuestionnaire = async () => {
            try {
                const response = await fetch(`${DOCUMENTS_URL}/${item.textContent}`);
                if (!response.ok) {
                    setGetQuestionnaireError(true);
                }
                const data = await response.json();
                if (!isGeneratedQuestionnaire(data)) {
                    setGetQuestionnaireError(true);
                }
                const questionnaire = data as GeneratedQuestionnaire;
                setValue('questions', questionnaire.questions);
            } catch (error) {
                setGetQuestionnaireError(true);
            }
        };

        getQuestionnaire();
    }, [item.textContent]); // eslint-disable-line

    const { modifyData: approveQuestionnaire, loading: approving } = useModifyRequest(
        `activities/task/${item.activityId}/approve-questionnaire`,
        'PUT',
    );

    const { deleteData: rejectStatus, deleteInProgress: rejecting } = useDeleteRequest(
        `generate-content-statuses/${item.id}`,
    );

    const approve = async (formData: GeneratedQuestionnaire) => {
        const { errors } = await approveQuestionnaire({
            questions: formData.questions,
        });
        if (errors && errors.length) {
            setShowError(true);
        } else {
            await context.refetchCourse();
            backButton();
        }
    };

    const reject = async () => {
        const { errors } = await rejectStatus();
        if (errors && errors.length) {
            const error: AlertError = {
                title: strings.courseAIGeneratedContentComponent.rejectAlert.alertErrorTitle,
                description: strings.courseAIGeneratedContentComponent.rejectAlert.alertErrorDescription,
            };
            setAlertError(error);
        } else {
            await context.refetchCourse();
        }
    };

    const removeQuestion = (questionIndex: number) => {
        const newQuestions = questions.filter((q, index) => index !== questionIndex);
        unregister(`questions.${questionIndex}`);
        setValue('questions', newQuestions);
    };

    if (getQuestionnaireError) {
        <Container>
            <NavBackButton
                backButtonTitle={''}
                backButtonAria={strings.courseAIGeneratedContentComponent.backButtonAria}
                backButtonHref={''}
                buttonFunctionOverride={backButton}
            />
            <GenerateTypeContentComponent generateType={item.generateType} />
            <Headline4>{`${strings.courseAIGeneratedContentComponent.questionnairePrefix} ${item.activityOrder}: ${item.activityTitle}`}</Headline4>
            <BodyLarge>{strings.courseAIGeneratedContentComponent.contentTitle}</BodyLarge>
            <ErrorNotifier
                title={strings.courseAIGeneratedContentComponent.reviewGeneratedTextComponent.error.title}
                description={strings.courseAIGeneratedContentComponent.reviewGeneratedTextComponent.error.description}
            />
        </Container>;
    }

    return (
        <>
            {openRejectAlert && (
                <Alert
                    buttonText={strings.courseAIGeneratedContentComponent.rejectAlert.alertButton}
                    ariaButtonText={strings.courseAIGeneratedContentComponent.rejectAlert.alertButtonAria}
                    alertText={strings.courseAIGeneratedContentComponent.rejectAlert.alertText}
                    closeAlert={() => setOpenRejectAlert(false)}
                    onSubmit={reject}
                    submitting={rejecting}
                    error={alertError}
                />
            )}
            <Container>
                <NavBackButton
                    backButtonTitle={''}
                    backButtonAria={strings.courseAIGeneratedContentComponent.backButtonAria}
                    backButtonHref={''}
                    buttonFunctionOverride={backButton}
                />
                <GenerateTypeContentComponent generateType={item.generateType} />

                <Headline4>{`${strings.courseAIGeneratedContentComponent.questionnairePrefix} ${item.activityOrder}: ${item.activityTitle}`}</Headline4>
                <ContentCard>
                    <Hint hint={strings.courseAIGeneratedContentComponent.questionnaireHint} />
                    <ApproveQuestionnaireForm onSubmit={handleSubmit(approve)}>
                        <QuestionsList>
                            {questions.map((q, i) => {
                                let questionErrorMessage = '';
                                let helpTextErrorMessage = '';

                                if (errors.questions && errors.questions[i] !== undefined) {
                                    questionErrorMessage = errors.questions[i]?.question?.message ?? '';
                                    helpTextErrorMessage = errors.questions[i]?.hint?.message ?? '';
                                }

                                return (
                                    <React.Fragment key={i}>
                                        <QuestionCard>
                                            <TextField
                                                fieldName={`question-${i}`}
                                                labelText={strings.questionnaireQuestionsForm.questionInputLabel}
                                                inputAria={strings.questionnaireQuestionsForm.questionInputAria}
                                                inputProps={register(`questions.${i}.question`, {
                                                    required: {
                                                        value: true,
                                                        message: requiredFieldErrorMessage(
                                                            strings.questionnaireQuestionsForm.questionInputLabel,
                                                        ),
                                                    },
                                                    maxLength: {
                                                        value: 120,
                                                        message: createErrorMessage(
                                                            strings.questionnaireQuestionsForm.questionInputLabel,
                                                            fieldErrorCodes.maxLength,
                                                        ),
                                                    },
                                                })}
                                                errorMessage={questionErrorMessage}
                                                required
                                            />

                                            <HelperTextHintAndTextField>
                                                <TextField
                                                    fieldName={`help-text-${i}`}
                                                    labelText={strings.questionnaireQuestionsForm.helpTextInputLabel}
                                                    inputAria={strings.questionnaireQuestionsForm.helpTextInputAria}
                                                    inputProps={register(`questions.${i}.hint`, {
                                                        maxLength: {
                                                            value: 120,
                                                            message: createErrorMessage(
                                                                strings.questionnaireQuestionsForm.helpTextInputLabel,
                                                                fieldErrorCodes.maxLength,
                                                            ),
                                                        },
                                                    })}
                                                    errorMessage={helpTextErrorMessage}
                                                />
                                            </HelperTextHintAndTextField>
                                            <SecondaryButton
                                                title={strings.questionnaireQuestionsForm.removeQuestionButton}
                                                aria={strings.questionnaireQuestionsForm.removeQuestionButtonAria}
                                                onClick={() => removeQuestion(i)}
                                                icon={<BinIcon size={IconSize.MEDIUM} />}
                                                alternateColour={SecondaryButtonColour.ALTERNATE}
                                            />
                                        </QuestionCard>
                                        <QuestionDivider />
                                    </React.Fragment>
                                );
                            })}
                        </QuestionsList>
                        <ButtonsRow>
                            <SecondaryButton
                                title={strings.courseAIGeneratedContentComponent.rejectButton.title}
                                aria={strings.courseAIGeneratedContentComponent.rejectButton.aria}
                                onClick={() => setOpenRejectAlert(true)}
                                icon={
                                    <BinIconContainer>
                                        <BinIcon size={IconSize.SMALL} colour={theme.secondaryButtonIconColorDanger} />
                                    </BinIconContainer>
                                }
                                alternateColour={SecondaryButtonColour.DANGER}
                                type={'button'}
                            />
                            <PrimaryButton
                                title={strings.courseAIGeneratedContentComponent.approveButton.title}
                                aria={strings.courseAIGeneratedContentComponent.approveButton.aria}
                                type={'submit'}
                                submitting={approving}
                            />
                        </ButtonsRow>
                    </ApproveQuestionnaireForm>
                </ContentCard>

                {showError && (
                    <ErrorRow>
                        <ErrorNotifierSlim description={strings.courseAIGeneratedContentComponent.approveError} />
                    </ErrorRow>
                )}
            </Container>
        </>
    );
}

const ApproveQuestionnaireForm = styled(ApproveFormCard)`
    padding: unset;
`;

const QuestionDivider = styled.hr`
    border-top: 1px solid ${theme.cardSecondaryBorder};
    margin-top: ${sizes.spacingMd};
    margin-bottom: ${sizes.spacingMd};
`;

const QuestionsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingXs};
    width: 100%;
`;

const ButtonsRow = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
    gap: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
    }
`;

type Question = {
    question: string;
    hint: string;
};

type GeneratedQuestionnaire = {
    questions: Question[];
};

function isGeneratedQuestionnaire(obj: any): obj is GeneratedQuestionnaire {
    return (
        obj &&
        typeof obj === 'object' &&
        Array.isArray(obj.questions) &&
        obj.questions.every(
            (item: any) =>
                item && typeof item === 'object' && typeof item.question === 'string' && typeof item.hint === 'string',
        )
    );
}
