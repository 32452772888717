import { BodySmall, Headline5 } from 'components/Elements';
import Hint from 'components/Hint';
import BetaLabel from '../BetaLabel';
import BoltIcon from 'assets/icons/BoltIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import styled from 'styled-components';
import { breakpoints, sizes } from 'theme';
import strings from '../../../../strings/strings.json';
import { Activity, ActivityType, BuildMethod, GenerateContentStatus, GenerateType } from 'models/Activity';
import SecondaryButton from 'components/buttons/SecondaryButton';
import { useNavigate } from 'react-router-dom';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import React from 'react';
import { FormCard } from 'components/forms/FormComponents';
import RemoveButton from 'components/buttons/RemoveButton';
import AudioPlayer from 'pages/learner/components/AudioPlayer';

type ComponentStatus = 'none' | 'queued' | 'error' | 'review' | 'approved';

type GeneratedContentSectionProps = {
    task: Activity;
    setTask: React.Dispatch<React.SetStateAction<Activity>>;
};

export default function GeneratedContentSection({
    task,
    setTask: setActivity,
}: GeneratedContentSectionProps): JSX.Element {
    let status = determineGenerationStatus(task);
    let body = <></>;

    switch (status) {
        case 'none':
        default:
            return <></>;
        case 'queued':
        case 'review':
        case 'error':
            body = <GeneratedContentSectionReviewButton />;
            break;
        case 'approved':
            switch (task.type) {
                default:
                    return <></>;
                case ActivityType.TASK:
                    switch (task.buildMethod) {
                        case BuildMethod.TEXT_AND_IMAGE:
                            const removeAudio = () => {
                                setActivity({ ...task, audioDocument: null });
                            };

                            body = (
                                <>
                                    <Hint hint={strings.activityUpdatePage.generatedContent.hint.textToAudio} />
                                    <AudioAndRemoveButton>
                                        <AudioPlayer filepath={task.audioDocument?.s3Filepath ?? ''} />
                                        <RemoveButtonComponent onClick={removeAudio} />
                                    </AudioAndRemoveButton>
                                </>
                            );
                            break;
                        case BuildMethod.AUDIO:
                        case BuildMethod.VIDEO:
                            if (!task.content) return <></>;

                            const removeText = () => {
                                setActivity({ ...task, content: '' });
                            };

                            let hint: string;

                            switch (task.buildMethod) {
                                case BuildMethod.AUDIO:
                                    hint = strings.activityUpdatePage.generatedContent.hint.audioToText;
                                    break;
                                case BuildMethod.VIDEO:
                                    hint = strings.activityUpdatePage.generatedContent.hint.videoToText;
                                    break;
                            }

                            body = (
                                <>
                                    <Hint hint={hint} />
                                    <TextAndRemoveButton>
                                        <BodySmall>{task.content}</BodySmall>
                                        <RemoveButtonComponent onClick={removeText} />
                                    </TextAndRemoveButton>
                                </>
                            );
                            break;
                    }
            }
    }

    return <GeneratedContentSectionBase>{body}</GeneratedContentSectionBase>;
}

function GeneratedContentSectionReviewButton(): JSX.Element {
    const navigate = useNavigate();
    const context = React.useContext(EditCourseContext);

    return (
        <SecondaryButton
            title={strings.activityUpdatePage.generatedContent.toReview}
            aria={strings.activityUpdatePage.generatedContent.toReview}
            onClick={() =>
                navigate(`/edit-course/${context.course!.id}`, {
                    state: { stepId: 'courseAIGeneratedContent' },
                })
            }
        />
    );
}

export function GeneratedContentSectionQuestionnaire(): JSX.Element {
    return (
        <GeneratedContentSectionBase>
            <GeneratedContentSectionReviewButton />
        </GeneratedContentSectionBase>
    );
}

export function determineGenerationStatus(activity: Activity): ComponentStatus {
    let generateStatus: GenerateContentStatus | undefined;

    switch (activity.type) {
        case ActivityType.TASK:
            generateStatus = activity.generateContentStatuses.find((s) => {
                switch (activity.buildMethod) {
                    case BuildMethod.TEXT_AND_IMAGE:
                        return s.generateType === GenerateType.TEXT_TO_AUDIO;
                    case BuildMethod.AUDIO:
                        return s.generateType === GenerateType.AUDIO_TO_TEXT;
                    case BuildMethod.VIDEO:
                        return s.generateType === GenerateType.VIDEO_TO_TEXT;
                    default:
                        return undefined;
                }
            });
            break;
        case ActivityType.QUESTIONNAIRE:
            generateStatus = activity.generateContentStatuses.find(
                (s) => s.generateType === GenerateType.MODULE_QUESTIONNAIRE,
            );
            break;
        default:
            break;
    }

    if (!generateStatus) {
        return 'none';
    } else if (!generateStatus.dateGenerated && !generateStatus.dateErrored && !generateStatus.dateApproved) {
        return 'queued';
    } else if (generateStatus.dateGenerated && !generateStatus.dateErrored && !generateStatus.dateApproved) {
        return 'review';
    } else if (generateStatus.dateErrored) {
        return 'error';
    } else if (
        generateStatus.dateGenerated &&
        !generateStatus.dateErrored &&
        generateStatus.dateApproved &&
        isGeneratedContentAddedToActivity(activity)
    ) {
        return 'approved';
    } else {
        return 'none';
    }
}

type GeneratedContentSectionBaseProps = {
    children: React.ReactNode;
};

function GeneratedContentSectionBase({ children }: GeneratedContentSectionBaseProps): JSX.Element {
    return (
        <ResourceGroup>
            <GeneratedContentCard as="div">
                <Container>
                    <Header>
                        <BoltAndTitle>
                            <BoltIcon size={IconSize.MEDIUM} />
                            <Headline5>{strings.activityUpdatePage.generatedContent.title}</Headline5>
                        </BoltAndTitle>
                        <BetaLabel />
                    </Header>
                    {children}
                </Container>
            </GeneratedContentCard>
        </ResourceGroup>
    );
}

function isGeneratedContentAddedToActivity(activity: Activity): boolean {
    switch (activity.type) {
        case ActivityType.TASK:
            switch (activity.buildMethod) {
                case BuildMethod.TEXT_AND_IMAGE:
                    return !!activity.audioDocument?.s3Filepath;
                case BuildMethod.AUDIO:
                case BuildMethod.VIDEO:
                    return !!activity.content;
                default:
                    return false;
            }
        case ActivityType.QUESTIONNAIRE:
            return !!activity.questions?.length;
        default:
            return false;
    }
}

type RemoveButtonComponentProps = {
    onClick: () => void;
};

function RemoveButtonComponent({ onClick }: RemoveButtonComponentProps): JSX.Element {
    return (
        <RemoveButtonRow>
            <RemoveButton
                title={strings.activityUpdatePage.generatedContent.removeButton.title}
                aria={strings.activityUpdatePage.generatedContent.removeButton.aria}
                onClick={onClick}
                submitting={false}
            />
        </RemoveButtonRow>
    );
}

const RemoveButtonRow = styled.div`
    display: flex;
    justify-content: end;
    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
        justify-content: unset;
    }
`;

const TextAndRemoveButton = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const AudioAndRemoveButton = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    align-items: center;
    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
        align-items: start;
    }
`;

const BoltAndTitle = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
    align-items: center;
`;

const Header = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const GeneratedContentCard = styled(FormCard)`
    width: 100%;
`;

const ResourceGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;
