import Alert from 'components/alert/Alert';
import strings from '../../../../../strings/strings.json';
import React from 'react';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import useDeleteRequest from 'hooks/useDeleteRequest';
import { logError } from 'lib/debug-helpers';
import { publish } from 'lib/events';

type DeleteActivityProps = {
    taskId: string;
    returnToCourseStructure: () => void;
    setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function DeleteActivityAlert({ taskId, returnToCourseStructure, setAlertOpen }: DeleteActivityProps) {
    const context = React.useContext(EditCourseContext);

    const { deleteData, deleteInProgress } = useDeleteRequest(`activities/${taskId}`);

    async function onDelete() {
        const response = await deleteData();

        if (response.errors) {
            logError(response.errors);
            return;
        }

        publish('ACTIVITY_DELETED', { id: taskId });

        await context.refetchCourse();
        returnToCourseStructure();
        setAlertOpen(false);
    }
    return (
        <Alert
            buttonText={strings.courseContentPage.alertButton}
            ariaButtonText={strings.courseContentPage.alertButtonAria}
            alertText={strings.courseContentPage.alertText}
            closeAlert={() => setAlertOpen(false)}
            onSubmit={onDelete}
            submitting={deleteInProgress}
        />
    );
}
