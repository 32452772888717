import { BodyLarge, BodyRegular, Headline4 } from 'components/Elements';
import PrimaryButton from 'components/buttons/PrimaryButton';
import NavBackButton from 'components/buttons/NavBackButton';
import { theme } from 'theme';
import { GenerateTypeContentComponent } from '../GenerateTypeContentComponent';
import useModifyRequest from 'hooks/useModifyRequest';
import React from 'react';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import strings from '../../../../../strings/strings.json';
import Hint from 'components/Hint';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import { useNavigate } from 'react-router-dom';
import { StepId } from 'pages/edit-course/CourseEditorPage';
import BinIcon from 'assets/icons/controls/BinIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import Alert, { AlertError } from 'components/alert/Alert';
import useDeleteRequest from 'hooks/useDeleteRequest';
import useWindowWidth from 'hooks/useWindowWidth';
import {
    BinIconContainer,
    ButtonsRow,
    Container,
    ContentCard,
    EditAndApproveButtonsContainer,
    ErrorRow,
    ReviewGeneratedContentProps,
} from './ReviewGeneratedSharedComponents';
import AudioPlayer from 'pages/learner/components/AudioPlayer';
import { styled } from 'styled-components';

export default function ReviewGeneratedAudioComponent({ item, backButton }: ReviewGeneratedContentProps): JSX.Element {
    const context = React.useContext(EditCourseContext);
    const [showError, setShowError] = React.useState<boolean>();
    const [openRejectAlert, setOpenRejectAlert] = React.useState(false);
    const [alertError, setAlertError] = React.useState<AlertError>();

    const { modifyData: approveAudio, loading } = useModifyRequest(
        `activities/task/${item.activityId}/approve-audio`,
        'PUT',
    );

    const { deleteData: rejectStatus, deleteInProgress: rejecting } = useDeleteRequest(
        `generate-content-statuses/${item.id}`,
    );

    const navigate = useNavigate();
    const { isDesktopWidth } = useWindowWidth();

    const approveClick = async () => {
        const { value, errors } = await approveAudio({});

        if ((errors && errors.length > 0) || (!value as boolean)) {
            setShowError(true);
        } else {
            await context.refetchCourse();
            backButton();
        }
    };

    const rejectClick = async () => {
        const { value, errors } = await rejectStatus();

        if ((errors && errors.length > 0) || (value as boolean) === false) {
            const error: AlertError = {
                title: strings.courseAIGeneratedContentComponent.rejectAlert.alertErrorTitle,
                description: strings.courseAIGeneratedContentComponent.rejectAlert.alertErrorDescription,
            };
            setAlertError(error);
        } else {
            await context.refetchCourse();
        }
    };

    return (
        <>
            {openRejectAlert && (
                <Alert
                    buttonText={strings.courseAIGeneratedContentComponent.rejectAlert.alertButton}
                    ariaButtonText={strings.courseAIGeneratedContentComponent.rejectAlert.alertButtonAria}
                    alertText={strings.courseAIGeneratedContentComponent.rejectAlert.alertText}
                    closeAlert={() => setOpenRejectAlert(false)}
                    onSubmit={rejectClick}
                    submitting={rejecting}
                    error={alertError}
                />
            )}
            <Container>
                <NavBackButton
                    backButtonTitle={''}
                    backButtonAria={strings.courseAIGeneratedContentComponent.backButtonAria}
                    backButtonHref={''}
                    buttonFunctionOverride={backButton}
                />
                <GenerateTypeContentComponent generateType={item.generateType} />

                <Headline4>{`${strings.courseAIGeneratedContentComponent.taskPrefix} ${item.activityOrder}: ${item.activityTitle}`}</Headline4>
                <BodyLarge>{strings.courseAIGeneratedContentComponent.contentTitle}</BodyLarge>
                <ContentCard>
                    <Hint hint={strings.courseAIGeneratedContentComponent.textToAudioHint} />
                    <AudioPlayer filepath={item.mediaContent} />
                    <TextContentContainer>
                        <BodyRegular>{item.textContent}</BodyRegular>
                    </TextContentContainer>
                    <ButtonsRow>
                        <SecondaryButton
                            title={strings.courseAIGeneratedContentComponent.rejectButton.title}
                            aria={strings.courseAIGeneratedContentComponent.rejectButton.aria}
                            onClick={() => setOpenRejectAlert(true)}
                            icon={
                                <BinIconContainer>
                                    <BinIcon size={IconSize.SMALL} colour={theme.secondaryButtonIconColorDanger} />
                                </BinIconContainer>
                            }
                            alternateColour={SecondaryButtonColour.DANGER}
                        />
                        <EditAndApproveButtonsContainer>
                            {isDesktopWidth && (
                                <SecondaryButton
                                    title={strings.courseAIGeneratedContentComponent.editTextButton.title}
                                    aria={strings.courseAIGeneratedContentComponent.editTextButton.aria}
                                    onClick={() =>
                                        navigate(`/edit-course/${context.course!.id}`, {
                                            state: {
                                                stepId: 'courseStructureAndContent' as StepId,
                                                editActivityId: item.activityId,
                                            },
                                        })
                                    }
                                />
                            )}
                            <PrimaryButton
                                title={strings.courseAIGeneratedContentComponent.approveButton.title}
                                aria={strings.courseAIGeneratedContentComponent.approveButton.aria}
                                onClick={approveClick}
                                submitting={loading}
                            />
                        </EditAndApproveButtonsContainer>
                    </ButtonsRow>
                    {showError && (
                        <ErrorRow>
                            <ErrorNotifierSlim description={strings.courseAIGeneratedContentComponent.approveError} />
                        </ErrorRow>
                    )}
                </ContentCard>
            </Container>
        </>
    );
}

const TextContentContainer = styled.div`
    width: 100%;
`;
