export type LiveTrainingNotificationModel = {
    meetingDateTime: Date;
    meetingDurationInMins: number | null;
    activityTitle: string;
    courseTitle: string;
    moduleTitle: string;
    courseScheduleId: string;
    activityId: string;
};

export type NotificationModel = {
    id: string;
    dateCreated: Date;
    message: string;
    link: string;
    acknowledged: boolean;
    type: NotificationType;
};

export enum NotificationType {
    GENERAL = 'GENERAL',
    CONFIGURE_ACCESS_REQUIREMENTS = 'CONFIGURE_ACCESS_REQUIREMENTS',
    COURSE_FULL = 'COURSE_FULL',
    LEARNER_REMOVED = 'LEARNER_REMOVED',
    TRAINER_INVITE = 'TRAINER_INVITE',
    TRAINER_COURSE_CANCELLED = 'TRAINER_COURSE_CANCELLED',
    LEARNER_COURSE_CANCELLED = 'LEARNER_COURSE_CANCELLED',
    COURSE_UNDER_MIN_LEARNERS = 'COURSE_UNDER_MIN_LEARNERS',
    LEARNER_INVITE = 'LEARNER_INVITE',
    CONTENT_GENERATED = 'CONTENT_GENERATED',
    MODULE_QUESTIONNAIRE_GENERATED = 'MODULE_QUESTIONAIRE_GENERATED',
    UPCOMING_LIVE_TRAINING_IN_PERSON = 'UPCOMING_LIVE_TRAINING_IN_PERSON',
    UPCOMING_LIVE_TRAINING_ONLINE = 'UPCOMING_LIVE_TRAINING_ONLINE',
    IMPORT_NEW_LEARNER = 'IMPORT_NEW_LEARNER',
}