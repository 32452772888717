import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import strings from '../../../../../strings/strings.json';
import BinIcon from 'assets/icons/controls/BinIcon';
import { IconSize } from 'assets/icons/icon-sizes';

type DeleteActivityButtonProps = {
    setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function DeleteActivityButton({ setAlertOpen }: DeleteActivityButtonProps) {
    return (
        <SecondaryButton
            title={strings.courseContentPage.deleteActivityButton}
            aria={strings.courseContentPage.deleteActivityButtonAria}
            onClick={() => setAlertOpen(true)}
            icon={<BinIcon size={IconSize.MEDIUM} />}
            alternateColour={SecondaryButtonColour.ALTERNATE}
        />
    );
}
