import Stepper from 'components/stepper/Stepper';
import { Activity } from 'models/Activity';
import { createEmptyQuestionnaireActivity, QuestionnaireActivity } from 'models/inputModels/QuestionnaireActivity';
import EmptyForm from '../EmptyForm';
import {
    determineGenerationStatus,
    GeneratedContentSectionQuestionnaire,
} from '../taskContentUpdateForms/GeneratedContentSection';
import { EditQuestionnaireDetailsForm } from './QuestionnaireDetailsForm';
import { EditQuestionnaireQuestionsForm } from './QuestionnaireQuestionsForm';

type QuestionnaireUpdateFormProps = {
    questionnaire: Activity;
    completeUpdateQuestionnaire: () => void;
};

export default function QuestionnaireUpdateForm({
    questionnaire,
    completeUpdateQuestionnaire,
}: QuestionnaireUpdateFormProps): JSX.Element {
    const status = determineGenerationStatus(questionnaire);
    const manuallyCreated = status === 'none';
    const approvedGenerated = status === 'approved';
    const editable = manuallyCreated || approvedGenerated;

    if (editable) {
        const steps = [
            {
                name: 'Activity Details',
                Form: EditQuestionnaireDetailsForm,
            },
            { name: 'Questions', Form: EditQuestionnaireQuestionsForm },
        ];

        if (!questionnaire) {
            steps.unshift({ name: 'Activity Type', Form: EmptyForm });
        }

        return (
            <Stepper<QuestionnaireActivity>
                defaultObject={questionnaire || createEmptyQuestionnaireActivity()}
                steps={steps}
                defaultStep={0}
                complete={completeUpdateQuestionnaire}
            />
        );
    } else {
        return (
                <GeneratedContentSectionQuestionnaire />
        );
    }
}
