import React from 'react';
import { StrategyProps } from './strategies';
import AccessibilityNotifier from '../../notifiers/AccessibilityNotifier';
import { BodyRegular } from '../../Elements';
import strings from '../../../strings/strings.json';
import styled from 'styled-components';
import { sizes } from '../../../theme';

export default function VideoStrategy({
    localFile,
    setFileMetaData,
    setFileMetaDataValid,
}: StrategyProps): JSX.Element {
    const [videoDuration, setVideoDuration] = React.useState<number | undefined>(undefined);

    React.useEffect(() => {
        setFileMetaData(undefined);
        setFileMetaDataValid(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localFile]);

    const video = document.createElement('video');
    video.src = URL.createObjectURL(localFile);
    video.ondurationchange = function (_) {
        setVideoDuration(video.duration);
    };

    return (
        <>
            {videoDuration && (
                <BodyRegular>
                    <BoldText>{strings.fileUpload.videoDurationLabel}</BoldText> {Math.round(videoDuration / 60)}{' '}
                    {strings.fileUpload.videoDurationMinutesLabel}
                </BodyRegular>
            )}
            {videoDuration && videoDuration > 60 * 10 && (
                <AccessibilityNotifierContainer>
                    <AccessibilityNotifier accessibilityGuideKey={'videoTooLong'} />
                </AccessibilityNotifierContainer>
            )}
        </>
    );
}

const AccessibilityNotifierContainer = styled.div`
    padding-bottom: ${sizes.spacingRg};
`;

const BoldText = styled.span`
    font-weight: bold;
`;
