import * as React from 'react';
import { MainSectionWithGaps } from '../../../components/sections/MainSection';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../../theme';
import { Card, Headline1, Headline4, Headline6 } from '../../../components/Elements';
import { CustomBackgroundColourContext } from '../../../contextProviders/CustomBackgroundColourContext';
import LearningObjective from '../../../components/learningObjectives/LearningObjective';
import useGetRequest from '../../../hooks/useGetRequest';
import { Course } from '../../../models/Course';
import { useParams } from 'react-router-dom';
import strings from '../../../strings/strings.json';
import TitleAndDescription from '../editPublishedCourse/components/TitleAndDescription';
import Skeleton from 'react-loading-skeleton';
import EditableModuleList from '../../edit-course/components/EditableModuleList';
import EditIcon from '../../../assets/icons/controls/EditIcon';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import { useNavigate } from 'react-router';
import NumberOfLearnersSection from 'components/courseSchedule/NumberOfLearnersSection';

export default function EditPublishedCoursePage(): JSX.Element {
    const customBackgroundColourContext = React.useContext(CustomBackgroundColourContext);
    const params = useParams();
    const navigate = useNavigate();
    const { courseId } = params;

    const { data: course, loading } = useGetRequest<Course>(`courses/${courseId}`, !courseId);

    React.useEffect(() => {
        customBackgroundColourContext.setBackgroundColour(theme.editCourseBackgroundColour);

        return () => {
            customBackgroundColourContext.setBackgroundColour(null);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function navigateToEditModulePage(moduleId: string) {
        navigate(`/edit-published-course/${courseId}/module/${moduleId}`);
    }

    return (
        <MainSectionWithGaps>
            <div>
                <EditCourseHeadline $inverted>{strings.editPublishedCoursePage.pageTitle}</EditCourseHeadline>
                {loading ? (
                    <Skeleton height={200} />
                ) : (
                    <BasicCard>
                        <Grid>
                            <TitleAndDescriptionContainer>
                                <TitleAndDescription
                                    title={course?.title ?? ''}
                                    description={course?.description}
                                    isHeadlineTwo
                                />
                                <NumberOfLearnersContainer>
                                    <Headline6>{strings.numberOfLearnersSection.headline}</Headline6>
                                    <NumberOfLearnersSection
                                        minimumLearners={course?.minimumLearners ?? 0}
                                        maximumLearners={course?.maximumLearners ?? 0}
                                    />
                                </NumberOfLearnersContainer>
                            </TitleAndDescriptionContainer>
                            <SecondaryButton
                                title={strings.editPublishedCoursePage.editButtonTitle}
                                aria={strings.editPublishedCoursePage.editButtonTitleAria}
                                onClick={() => navigate(`/edit-published-course/${courseId}/title-and-description`)}
                                icon={<EditIcon />}
                            />
                        </Grid>
                    </BasicCard>
                )}
            </div>
            {loading ? (
                <Skeleton height={200} />
            ) : (
                <BasicCard>
                    <Grid>
                        <HeaderSection>
                            <TitleAndDescription title={strings.editPublishedCoursePage.learningObjectivesTitle} />
                        </HeaderSection>
                        <PrimaryLinkButtonContainer>
                            <SecondaryButton
                                title={strings.editPublishedCoursePage.editButtonTitle}
                                aria={strings.editPublishedCoursePage.editButtonTitleAria}
                                onClick={() => navigate(`/edit-published-course/${courseId}/learning-objectives`)}
                                icon={<EditIcon />}
                            />
                        </PrimaryLinkButtonContainer>
                        <LearningObjectivesSection>
                            {course?.learningObjectives.length === 0 ? (
                                <p>{strings.editPublishedCoursePage.noLearningObjectivesMessage}</p>
                            ) : (
                                course?.learningObjectives.map((learningObjective, index) => (
                                    <LearningObjective description={learningObjective.description} key={index} />
                                ))
                            )}
                        </LearningObjectivesSection>
                    </Grid>
                </BasicCard>
            )}

            <div>
                <CourseStructureHeadline $inverted>
                    {strings.editPublishedCoursePage.courseStructureTitle}
                </CourseStructureHeadline>
                {loading ? (
                    <Skeleton height={100} count={3} />
                ) : (
                    <EditableModuleList
                        courseId={courseId ?? ''}
                        modules={course?.modules ?? []}
                        editModuleClick={navigateToEditModulePage}
                        activitiesEditable={false}
                        hideAddModuleButton={true}
                    />
                )}
            </div>
        </MainSectionWithGaps>
    );
}

const NumberOfLearnersContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const BasicCard = styled(Card)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const PrimaryLinkButtonContainer = styled.div`
    grid-area: edit;
`;

const HeaderSection = styled.div`
    grid-area: title;
`;

const LearningObjectivesSection = styled.div`
    grid-area: objectives;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: ${sizes.spacingMd};
    grid-row-gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.md}) {
        grid-template-columns: repeat(1, 1fr);
        padding-top: ${sizes.spacingSm};
        padding-bottom: ${sizes.spacingSm};
    }
    white-space: pre-wrap;
`;

const Grid = styled.div`
    display: grid;
    grid-template-areas: 'title edit' 'objectives objectives';
    grid-template-columns: 3fr;
    gap: ${sizes.spacingRg};
    @media (max-width: ${breakpoints.sm}) {
        grid-template-areas: 'title' 'objectives' 'edit';
        grid-template-columns: unset;
        row-gap: ${sizes.spacingSm};
    }
`;

const TitleAndDescriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};

    @media (max-width: ${breakpoints.sm}) {
        padding-bottom: ${sizes.spacingMd};
    }
`;

const CourseStructureHeadline = styled(Headline4)`
    padding-bottom: ${sizes.spacingSm};
`;

const EditCourseHeadline = styled(Headline1)`
    padding-bottom: ${sizes.spacingMd};
`;
