import styled from 'styled-components';
import { sizes } from '../../../theme';
import { Activity, ActivityType, BuildMethod } from '../../../models/Activity';
import useGetRequest from '../../../hooks/useGetRequest';
import LiveTrainingUpdateForm from './LiveTrainingUpdateForm';
import { TaskUpdateForm } from './TaskUpdateForm';
import TaskUpdateFormForTextAndImage from './taskContentUpdateForms/TaskUpdateFormForTextAndImage';
import TaskUpdateFormForVideo from './taskContentUpdateForms/TaskUpdateFormForVideo';
import { TaskUpdateFormForAudio } from './taskContentUpdateForms/TaskUpdateFormForAudio';
import QuestionnaireUpdateForm from './questionnaire/QuestionnaireUpdateForm';

type ActivityUpdateComponentProps = {
    activityId: string;
    returnToCourseStructure: () => void;
    focusContent?: boolean;
};

export default function ActivityUpdateComponent({
    activityId,
    returnToCourseStructure,
    focusContent,
}: ActivityUpdateComponentProps): JSX.Element {
    const { data: selectedActivity, refetchData: refetchActivity } = useGetRequest<Activity>(
        `activities/${activityId}`,
        !activityId,
    );

    if (!selectedActivity) {
        return <></>;
    }

    switch (selectedActivity.type) {
        case ActivityType.QUESTIONNAIRE:
            return (
                <QuestionnaireUpdateForm
                    questionnaire={selectedActivity}
                    completeUpdateQuestionnaire={returnToCourseStructure}
                />
            );

        case ActivityType.LIVE_TRAINING:
            return (
                <LiveTrainingUpdateForm
                    activity={selectedActivity}
                    returnToCourseStructure={returnToCourseStructure}
                />
            );

        default:
        case ActivityType.TASK:
            switch (selectedActivity.buildMethod) {
                case BuildMethod.TEXT_AND_IMAGE:
                    return (
                        <TaskUpdateFormForTextAndImage
                            activity={selectedActivity}
                            refetchActivity={refetchActivity}
                            focusContent={focusContent}
                            returnToCourseStructure={returnToCourseStructure}
                        />
                    );
                case BuildMethod.AUDIO:
                    return (
                        <TaskUpdateFormForAudio
                            task={selectedActivity}
                            refetchActivity={refetchActivity}
                            returnToCourseStructure={returnToCourseStructure}
                        />
                    );
                case BuildMethod.VIDEO:
                    return (
                        <TaskUpdateFormForVideo
                            task={selectedActivity}
                            refetchActivity={refetchActivity}
                            returnToCourseStructure={returnToCourseStructure}
                        />
                    );
                default: //for tasks created before build methods were introduced
                case BuildMethod.NONE:
                    return (
                        <TaskUpdatePageContent
                            activity={selectedActivity}
                            refetchActivity={refetchActivity}
                            returnToCourseStructure={returnToCourseStructure}
                        />
                    );
            }
    }
}

const TaskUpdatePageContent = styled(TaskUpdateForm)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: ${sizes.spacingMd};
`;
