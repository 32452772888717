import { BodyRegular, BodySmall, Card, FlexAlignCenter, Headline3, Headline6 } from 'components/Elements';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import React from 'react';
import { styled } from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import strings from '../../../../strings/strings.json';
import BetaLabel from '../BetaLabel';
import ReviewGeneratedAudioComponent from './reviewGeneratedContentComponents/ReviewGeneratedAudioComponent';
import ReviewGeneratedTextComponent from './reviewGeneratedContentComponents/ReviewGeneratedTextComponent';
import { ModuleItem } from './ModuleItem';
import { ActivityType, GenerateErrorReason, GenerateType } from 'models/Activity';
import { CourseGeneratedContentIsApproved } from 'models/Course';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import ReviewGeneratedModuleQuestionnaire from './reviewGeneratedContentComponents/ReviewGeneratedModuleQuestionnaire';
import { ItemCard, ModuleListItem } from './SharedStyles';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import ValidateIcon from 'assets/icons/indicators/ValidateIcon';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import PreviewIcon from 'assets/icons/controls/PreviewIcon';
import { StepId } from 'pages/edit-course/CourseEditorPage';
import RefreshIcon from 'assets/icons/RefreshIcon';
import BinIcon from 'assets/icons/controls/BinIcon';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import useDeleteRequest from 'hooks/useDeleteRequest';
import useModifyRequest from 'hooks/useModifyRequest';
import { PercentageProgressBar } from 'components/progressBar/ProgressBar';
import { logError } from 'lib/debug-helpers';
import Alert from 'components/alert/Alert';
import useWindowWidth from 'hooks/useWindowWidth';
import Hint from 'components/Hint';
import { GenerateItem } from './TaskGenerateItem';

type CourseAIGeneratedContentComponentProps = {
    showIncompleteStepsWarning?: boolean;
};

export default function CourseAIGeneratedContentComponent({
    showIncompleteStepsWarning,
}: CourseAIGeneratedContentComponentProps): JSX.Element {
    const context = React.useContext(EditCourseContext);
    const navigate = useNavigate();
    const { aboveTabletWidth, belowTabletWidth } = useWindowWidth();

    const [idOfStatusToDelete, setIdOfStatusToDelete] = React.useState<string>('');
    const [idOfStatusToRetry, setIdOfStatusToRetry] = React.useState<string>('');
    const [taskIdToReview, setTaskIdToReview] = React.useState<string>('');

    const [retryStatusError, setRetryStatusError] = React.useState<boolean>(false);
    const [deleteStatusError, setDeleteStatusError] = React.useState<boolean>(false);
    const [openDeleteAlert, setOpenDeleteAlert] = React.useState<boolean>(false);
    const [refreshKey, setRefreshKey] = React.useState(0);
    const [isDisabled, setIsDisabled] = React.useState(false);

    const now = new Date();

    const { deleteData, deleteInProgress } = useDeleteRequest(`generate-content-statuses/${idOfStatusToDelete}`);

    const { modifyData: generateAudio, loading: generatingAudio } = useModifyRequest(
        `generate-content-statuses/${idOfStatusToRetry}/retry`,
        'POST',
    );

    async function handleRefresh() {
        try {
            setIsDisabled(true);
            await context.refetchCourse();
            setRefreshKey((prev) => prev + 1);

            setTimeout(() => {
                setIsDisabled(false);
            }, 4000); // disable button for 4 seconds after click
        } catch (error) {
            logError(error);
            setIsDisabled(false);
        }
    }

    React.useEffect(() => {
        if (!idOfStatusToRetry) {
            return;
        }
        async function fetchData() {
            try {
                await generateAudio({});
                await context.refetchCourse();
                setIdOfStatusToRetry('');
            } catch (error) {
                logError(error);
                setRetryStatusError(true);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idOfStatusToRetry]);

    const closeDeleteAlert = () => {
        setDeleteStatusError(false);
        setIdOfStatusToDelete('');
        setOpenDeleteAlert(false);
    };

    const deleteStatus = async () => {
        const response = await deleteData();

        if (response.errors && response.errors.length > 0) {
            logError(response.errors);
            setDeleteStatusError(true);
            return;
        }

        await context.refetchCourse();
        closeDeleteAlert();
    };

    const moduleItems: ModuleItem[] = [];

    context
        .course!.modules.sort((moduleA, moduleB) => {
            return moduleA.order - moduleB.order;
        })
        .forEach((module) => {
            const generateContentItems: GenerateItem[] = [];

            module.activities
                .filter(
                    (activity) => activity.type === ActivityType.TASK || activity.type === ActivityType.QUESTIONNAIRE,
                )
                .sort((taskA, taskB) => {
                    return taskA.order - taskB.order;
                })
                .forEach((task) => {
                    if (!task.generateContentStatuses) return;

                    task.generateContentStatuses.forEach((status) => {
                        let mediaContent = '';
                        let textContent = '';

                        switch (status.generateType) {
                            default:
                            case GenerateType.TEXT_TO_AUDIO:
                                mediaContent = status.s3Filepath;
                                textContent = task.content;
                                break;
                            case GenerateType.AUDIO_TO_TEXT:
                                mediaContent = task.audioDocument?.s3Filepath ?? '';
                                textContent = status.s3Filepath;
                                break;
                            case GenerateType.VIDEO_TO_TEXT:
                                mediaContent = task.videoDocument?.s3Filepath ?? '';
                                textContent = status.s3Filepath;
                                break;
                            case GenerateType.MODULE_QUESTIONNAIRE:
                                textContent = status.s3Filepath;
                        }

                        const taskItem: GenerateItem = {
                            activityId: task.id,
                            activityType: task.type,
                            activityOrder: task.order,
                            activityTitle: task.title,
                            textContent: textContent,
                            mediaContent: mediaContent,
                            id: status.id,
                            generateType: status.generateType,
                            dateCreated: status.dateCreated,
                            dateUpdated: status.dateUpdated,
                            dateGenerated: status.dateGenerated,
                            s3Filepath: status.s3Filepath,
                            dateErrored: status.dateErrored,
                            error: status.error,
                            dateApproved: status.dateApproved,
                        };

                        generateContentItems.push(taskItem);
                    });
                });

            moduleItems.push({
                moduleOrder: module.order,
                moduleTitle: module.title,
                generateItems: generateContentItems,
            });
        });

    if (taskIdToReview) {
        const taskContent = moduleItems
            .flatMap((module) => module.generateItems)
            .find((task) => task.id === taskIdToReview);

        if (taskContent) {
            switch (taskContent.generateType) {
                case GenerateType.TEXT_TO_AUDIO:
                    return (
                        <ReviewGeneratedAudioComponent item={taskContent} backButton={() => setTaskIdToReview('')} />
                    );
                case GenerateType.AUDIO_TO_TEXT:
                case GenerateType.VIDEO_TO_TEXT:
                    return (
                        <ReviewGeneratedTextComponent item={taskContent} backButton={() => setTaskIdToReview('')} />
                    );
                case GenerateType.MODULE_QUESTIONNAIRE:
                    return (
                        <ReviewGeneratedModuleQuestionnaire
                            item={taskContent}
                            backButton={() => setTaskIdToReview('')}
                        />
                    );
                default:
                    return <></>;
            }
        }
    }

    if (openDeleteAlert) {
        return (
            <Alert
                alertText={strings.courseAIGeneratedContentComponent.deleteAlert.text}
                buttonText={strings.courseAIGeneratedContentComponent.deleteAlert.button.text}
                ariaButtonText={strings.courseAIGeneratedContentComponent.deleteAlert.button.aria}
                closeAlert={closeDeleteAlert}
                onSubmit={deleteStatus}
                error={
                    deleteStatusError
                        ? {
                              title: strings.courseAIGeneratedContentComponent.deleteAlert.error.title,
                              description: strings.courseAIGeneratedContentComponent.deleteAlert.error.description,
                          }
                        : undefined
                }
            />
        );
    }

    const tasks = moduleItems.flatMap((module) => module.generateItems);

    if (!tasks.length) {
        return (
            <>
                <TitleRow>
                    <Headline3>{strings.courseEditorPage.steps.aiGeneratedContent.title}</Headline3>
                    <BetaLabel />
                </TitleRow>
                <ModulesList>
                    <BodyRegular $bold>
                        {strings.courseAIGeneratedContentComponent.noContentGenerated.text}
                    </BodyRegular>
                    <Hint hint={strings.courseAIGeneratedContentComponent.noContentGenerated.hintText} />
                    <PrimaryButton
                        title={strings.courseAIGeneratedContentComponent.noContentGenerated.button.title}
                        aria={strings.courseAIGeneratedContentComponent.noContentGenerated.button.aria}
                        fullwidth
                        onClick={() =>
                            navigate(`/edit-course/${context.course!.id}`, {
                                state: {
                                    stepId: 'courseStructureAndContent' as StepId,
                                },
                            })
                        }
                    />
                </ModulesList>
            </>
        );
    }

    return (
        <ComponentContainer>
            <TitleRow>
                <TitleAndLabel>
                    <Headline3>{strings.courseEditorPage.steps.aiGeneratedContent.title}</Headline3>
                    <BetaLabel />
                </TitleAndLabel>
                <SecondaryButton
                    title={strings.courseAIGeneratedContentComponent.refreshButton.title}
                    aria={strings.courseAIGeneratedContentComponent.refreshButton.aria}
                    onClick={handleRefresh}
                    disabled={isDisabled}
                />
            </TitleRow>
            {!CourseGeneratedContentIsApproved(context.course!) && showIncompleteStepsWarning && (
                <NotificationContainer>
                    <Notification
                        title={strings.courseEditorPage.generatedContentToBeReviewed.title}
                        notificationType={NotificationType.WARNING}
                        maxWidth="100%"
                    />
                </NotificationContainer>
            )}
            <ModulesList key={refreshKey}>
                {moduleItems
                    .filter((module) => module.generateItems.length > 0)
                    .map((module, moduleIndex) => {
                        return (
                            <ModuleListItem key={moduleIndex}>
                                <Headline6>{`${strings.courseAIGeneratedContentComponent.moduleTitlePrefix} ${module.moduleOrder}: ${module.moduleTitle}`}</Headline6>

                                {module.generateItems.map((content, taskIndex) => {
                                    const errorStatus: JSX.Element | null = content.dateErrored ? (
                                        <ErrorNotifierSlim description={getFriendlyError(content.error)} />
                                    ) : null;

                                    let action: JSX.Element | null;

                                    if (content.dateApproved) {
                                        action = (
                                            <ApprovedContainer>
                                                <ApprovedLabel>
                                                    <ApprovedText $bold>
                                                        {strings.courseAIGeneratedContentComponent.approvedStatus}
                                                    </ApprovedText>
                                                    <ValidateIcon />
                                                </ApprovedLabel>
                                                <SecondaryButton
                                                    title={strings.courseAIGeneratedContentComponent.viewButton.title}
                                                    aria={strings.courseAIGeneratedContentComponent.viewButton.aria}
                                                    icon={<PreviewIcon />}
                                                    compact={aboveTabletWidth}
                                                    fullwidth={belowTabletWidth}
                                                    onClick={() =>
                                                        navigate(`/edit-course/${context.course!.id}`, {
                                                            state: {
                                                                stepId: 'courseStructureAndContent' as StepId,
                                                                editActivityId: content.activityId,
                                                            },
                                                        })
                                                    }
                                                />
                                            </ApprovedContainer>
                                        );
                                    } else if (content.dateErrored) {
                                        action = (
                                            <ButtonsContainer>
                                                <SecondaryButton
                                                    title={strings.courseAIGeneratedContentComponent.retryButton.title}
                                                    aria={strings.courseAIGeneratedContentComponent.retryButton.aria}
                                                    onClick={() => {
                                                        setIdOfStatusToRetry(content.id);
                                                    }}
                                                    icon={<RefreshIcon />}
                                                    submitting={deleteInProgress || generatingAudio}
                                                    compact={aboveTabletWidth}
                                                    fullwidth={belowTabletWidth}
                                                />
                                                <SecondaryButton
                                                    title={
                                                        strings.courseAIGeneratedContentComponent.deleteButton.title
                                                    }
                                                    aria={strings.courseAIGeneratedContentComponent.deleteButton.aria}
                                                    onClick={() => {
                                                        setIdOfStatusToDelete(content.id);
                                                        setOpenDeleteAlert(true);
                                                    }}
                                                    alternateColour={SecondaryButtonColour.DANGER}
                                                    icon={<BinIcon colour={theme.errorColour} />}
                                                    submitting={deleteInProgress || generatingAudio}
                                                    compact={aboveTabletWidth}
                                                    fullwidth={belowTabletWidth}
                                                />
                                            </ButtonsContainer>
                                        );
                                    } else if (content.dateGenerated) {
                                        action = (
                                            <SingleButtonContainer>
                                                <PrimaryButton
                                                    title={
                                                        strings.courseAIGeneratedContentComponent.reviewButton.title
                                                    }
                                                    aria={strings.courseAIGeneratedContentComponent.reviewButton.aria}
                                                    onClick={() => {
                                                        setTaskIdToReview(content.id);
                                                    }}
                                                    submitting={deleteInProgress}
                                                />
                                            </SingleButtonContainer>
                                        );
                                    } else {
                                        const expectedMinsToComplete = 5;

                                        const completionPercentage = getCompletionPercentage(
                                            expectedMinsToComplete,
                                            now,
                                            content.dateUpdated,
                                        );
                                        action = (
                                            <EstimatedTimeBase>
                                                <PercentageProgressBar
                                                    label={`${strings.courseAIGeneratedContentComponent.estimatedTime}: ${expectedMinsToComplete} ${strings.courseAIGeneratedContentComponent.min}`}
                                                    percentage={completionPercentage}
                                                />
                                            </EstimatedTimeBase>
                                        );
                                    }

                                    return (
                                        <ItemCard key={taskIndex}>
                                            <ItemCardContent>
                                                <TopRow>
                                                    <Description>
                                                        <BodyRegular>{`${strings.courseAIGeneratedContentComponent.taskPrefix} ${content.activityOrder}: ${content.activityTitle}`}</BodyRegular>
                                                    </Description>
                                                    {action}
                                                </TopRow>

                                                {retryStatusError && (
                                                    <ErrorRow>
                                                        {strings.courseAIGeneratedContentComponent.retryError}
                                                    </ErrorRow>
                                                )}
                                                {errorStatus && <ErrorRow>{errorStatus}</ErrorRow>}
                                            </ItemCardContent>
                                        </ItemCard>
                                    );
                                })}
                            </ModuleListItem>
                        );
                    })}
            </ModulesList>
        </ComponentContainer>
    );
}

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        align-items: unset;
    }
`;

const TitleAndLabel = styled(FlexAlignCenter)`
    gap: ${sizes.spacingMd};
`;

const NotificationContainer = styled.div`
    width: 100%;
`;

const ModulesList = styled(Card)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
`;

const ApprovedContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    align-items: center;
    flex: 45%;
    justify-content: space-between;
    min-width: 225px;

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        align-items: unset;
        gap: ${sizes.spacingSm};
        width: 100%;
    }
`;

const ApprovedLabel = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
`;

const SingleButtonContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    justify-content: end;
    flex: 45%;

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        width: 100%;
    }
`;

const EstimatedTimeBase = styled.div`
    display: flex;
    justify-content: end;
    flex: 45%;
    width: 100%;
    min-width: 225px;
`;

const ItemCardContent = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
    width: 100%;
    flex-direction: column;
`;

const ApprovedText = styled(BodySmall)`
    white-space: nowrap;
`;

const ButtonsContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    justify-content: space-between;
    flex: 45%;

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        width: 100%;
    }
`;

const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${sizes.spacingMd};
    width: 100%;

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        gap: ${sizes.spacingSm};
    }
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    flex: 80%;

    @media (max-width: ${breakpoints.md}) {
        width: 100%;
    }
`;

const ErrorRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

function getCompletionPercentage(expectedMinsToComplete: number, now: Date, dateCreated: Date): number {
    const tenMinsAfterDateCreated = new Date(dateCreated);
    tenMinsAfterDateCreated.setMinutes(tenMinsAfterDateCreated.getMinutes() + expectedMinsToComplete);

    const differenceInMinutes = (tenMinsAfterDateCreated.getTime() - new Date(now).getTime()) / (1000 * 60);

    const minsElapsed = expectedMinsToComplete - differenceInMinutes;

    const percentageComplete =
        minsElapsed >= expectedMinsToComplete ? 99 : Math.round((minsElapsed / expectedMinsToComplete) * 100);

    return percentageComplete;
}

function getFriendlyError(error: GenerateErrorReason): string {
    let friendlyError = '';

    switch (error) {
        case GenerateErrorReason.MISSING_ENV_VAR_POLLY_REGION:
        case GenerateErrorReason.MISSING_ENV_VAR_REGION:
        case GenerateErrorReason.MISSING_ENV_VAR_DOCUMENTS_REGION:
        case GenerateErrorReason.MISSING_ENV_VAR_DOCUMENTS_BUCKET_NAME:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.serverError;
            break;
        case GenerateErrorReason.ACTIVITY_NOT_TASK:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.activityNotTask;
            break;
        case GenerateErrorReason.TASK_NO_CONTENT:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.taskNoContent;
            break;
        case GenerateErrorReason.CONTENT_ALREADY_GENERATED:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.contentAlreadyGenerated;
            break;
        case GenerateErrorReason.POLLY_ERROR:
        case GenerateErrorReason.POLLY_RESPONSE_NO_AUDIO:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.pollyError;
            break;
        case GenerateErrorReason.UPLOAD_AUDIO_ERROR:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.uploadAudioError;
            break;
        case GenerateErrorReason.UPDATE_STATUS_ERROR:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.updateStatusError;
            break;
        case GenerateErrorReason.NONE:
        default:
            return '';
    }

    return `${strings.courseAIGeneratedContentComponent.generateContentErrors.prefix} ${friendlyError}`;
}
