export type Question = {
    question: string;
    helpText: string;
    order: number;
};

export type QuestionnaireActivity = {
    title: string;
    description: string;
    durationInMins: number | null;
    questions: Question[];
    moduleId: string;
    id: string;
};

export function createEmptyQuestionnaireActivity(moduleId?: string) {
    return {
        title: '',
        description: '',
        durationInMins: null,
        questions: [],
        moduleId: moduleId ?? '',
        id: '',
    };
}

