import * as React from 'react';
import { Headline4, Headline5, Headline6, BulletList } from 'components/Elements';
import strings from '../../../../strings/strings.json';
import styled from 'styled-components';
import useUpsertRequest from 'hooks/useUpsertRequest';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import { logError } from 'lib/debug-helpers';
import { sizes } from 'theme';
import Hint from 'components/Hint';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { DarkFormHead, MainContainer } from './SharedQuestionnaireComponents';

export type GenerateQuestionnaireTask = {
    id: string;
    title: string;
};

export type GenerateQuestionnaireFormProps = {
    moduleId: string;
    moduleTitle: string;
    tasks: GenerateQuestionnaireTask[];
    returnToModulesList: Function;
};

export default function GenerateModuleQuestionnaireForm({
    moduleId,
    moduleTitle,
    tasks,
    returnToModulesList,
}: GenerateQuestionnaireFormProps): JSX.Element {
    const context = React.useContext(EditCourseContext);
    const [error, setError] = React.useState<boolean>(false);

    const { modifyData: generateQuestionnaire, loading: generating } = useUpsertRequest(
        `modules/${moduleId}/generate-questionnaire`,
    );

    async function generate() {
        setError(false);

        const response = await generateQuestionnaire({
            moduleId: moduleId,
        });

        const { errors } = response;

        if (errors) {
            logError(errors);
            setError(true);
            return;
        }

        await context.refetchCourse();
        returnToModulesList();
    }

    return (
        <MainContainer>
            <DarkFormHead />
            <Container>
                <Headline4>{strings.questionnaireGenerateForm.instruction}</Headline4>
                <Hint
                    hint={strings.questionnaireGenerateForm.hint}
                />
                <Headline5>{strings.questionnaireGenerateForm.modulePrefix} {moduleTitle}</Headline5>
                <div>
                    <Headline6>{strings.questionnaireGenerateForm.listHeading}</Headline6>
                    <BulletList>
                        {tasks.map((t) => (
                            <li key={t.id}>{t.title}</li>
                        ))}
                    </BulletList>
                </div>
                <ButtonContainer>
                    {error && (
                        <ErrorNotifierSlim description={strings.questionnaireGenerateForm.generateError} />
                    )}
                    <PrimaryButton
                        title={strings.questionnaireGenerateForm.submitButton}
                        aria={strings.questionnaireGenerateForm.submitButtonAria}
                        onClick={generate}
                        submitting={generating}
                    />
                </ButtonContainer>
            </Container>
        </MainContainer>
    );
}

const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    padding: ${sizes.spacingMd};
`;
