import * as React from 'react';
import strings from '../../../../strings/strings.json';
import useNeveForm from 'components/forms/NeveForm';
import useUploadRequest from 'hooks/useUploadRequest';
import Spinner from 'components/Spinner';
import { TextAreaField } from 'components/forms/TextAreaField';
import styled from 'styled-components';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { Headline5 } from 'components/Elements';
import { FileMetaData } from 'components/fileUpload/strategies/strategies';
import { ErrorMessage, FormCard } from 'components/forms/FormComponents';
import TextField from 'components/forms/TextField';
import useModifyRequest from 'hooks/useModifyRequest';
import { validateThreeCharacterLimit } from 'lib/custom-form-validation';
import { logError } from 'lib/debug-helpers';
import { APIError } from 'lib/_api-helpers';
import { Activity } from 'models/Activity';
import { FileDocument } from 'models/FileDocument';
import {
    createGlobalErrorFromAPIError,
    createFieldErrorFromAPIError,
    requiredFieldErrorMessage,
    createErrorMessage,
    fieldErrorCodes,
} from 'shared/error-messages';
import { sizes } from 'theme';
import useFormBlocker from 'components/forms/useFormBlocker';
import { ResourcesInput } from '../ResourcesForm';
import { ImageCard } from '../FileCard';
import { ImageSelectionComponent } from '../FileSelectionComponent';
import ResourcesList from '../ResourcesList';
import { LearningResourcesFormModal } from '../LearningResourcesFormModal';
import { TaskInput } from './TaskUpdateDetailsSection';
import { arraysEqual } from 'lib/arraysEqual';
import GeneratedContentSection from './GeneratedContentSection';
import GenerateContentModal, { GenerateModalState } from '../taskContentForms/GenerateContentModal';
import { CheckboxOption } from 'components/forms/Checkboxes';
import SavedSuccessfullyNotification from './sharedUpdateComponents/SavedSuccessfullyNotification';
import { ButtonAndNotificationContainer } from './sharedUpdateComponents/ButtonAndNotificationContainer';
import DeleteActivityButton from './sharedUpdateComponents/DeleteActivityButton';
import DeleteActivityAlert from './sharedUpdateComponents/DeleteActivityAlert';

export interface TextAndImageTaskInput extends TaskInput {
    content: string;
}

type TaskUpdateFormProps = {
    activity: Activity;
    refetchActivity: () => Promise<void>;
    returnToCourseStructure: () => void;
    focusContent?: boolean;
};

export default function TaskUpdateFormForTextAndImage({
    activity: activityProp,
    refetchActivity,
    returnToCourseStructure,
    focusContent = false,
}: TaskUpdateFormProps): JSX.Element {
    const context = React.useContext(EditCourseContext);

    const [activity, setActivity] = React.useState<Activity>(activityProp);
    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');
    const [resources, setResources] = React.useState<ResourcesInput[]>();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [saved, setSaved] = React.useState<boolean>(false);
    const [localImageToBeUploaded, setLocalImageToBeUploaded] = React.useState<File | null | undefined>(null);
    const [providerImageToBeUploaded, setProviderImageToBeUploaded] = React.useState<FileDocument>();
    const [localImageMetaData, setLocalImageMetaData] = React.useState<FileMetaData | undefined>();
    const [imageCardComponentData, setImageCardComponentData] = React.useState<FileCardComponentData | null>(
        activityProp.imageDocument
            ? { fileName: activityProp.imageDocument.filename, fileDate: activityProp.imageDocument.dateUpdated }
            : null,
    );
    const [generateModalState, setGenerateModalState] = React.useState<GenerateModalState>('hide');
    const [addAudio, setAddAudio] = React.useState<boolean>(false);
    const [alertOpen, setAlertOpen] = React.useState(false);

    const selectImageButtonRef = React.useRef<HTMLButtonElement>(null);
    const addLinkButtonRef = React.useRef<HTMLButtonElement>(null);

    React.useEffect(() => {
        if (activityProp) {
            setActivity(activityProp);
            setResources(activityProp.resources);
            setLocalImageToBeUploaded(undefined);
            setProviderImageToBeUploaded(undefined);
            setGlobalErrorMessage('');
            setFormDirty(false);
        }
    }, [activityProp]);

    React.useEffect(() => {
        let data: FileCardComponentData | null;

        if (localImageToBeUploaded) {
            data = {
                fileName: localImageToBeUploaded.name,
                fileDate: new Date(),
            };
        } else if (providerImageToBeUploaded) {
            data = {
                fileName: providerImageToBeUploaded.filename,
                fileDate: providerImageToBeUploaded.dateUpdated,
            };
        } else if (activity.imageDocument) {
            data = {
                fileName: activity.imageDocument.filename,
                fileDate: activity.imageDocument.dateUpdated,
            };
        } else {
            data = null;
        }

        if (localImageToBeUploaded || providerImageToBeUploaded) {
            setSaved(false);
        }

        setImageCardComponentData(data);
    }, [activity, localImageToBeUploaded, providerImageToBeUploaded]);

    const { modifyData: updateTask, loading: updatingTask } = useModifyRequest(
        `activities/task/textimage/${activity.id}`,
        'PUT',
    );

    const { modifyData: generateAudio, loading: generatingAudio } = useModifyRequest(
        `activities/task/${activity.id}/generate/audio`,
        'POST',
    );

    React.useEffect(() => {
        if (generatingAudio) {
            setGenerateModalState('submitting');
        }
    }, [generatingAudio]);

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
        reset,
    } = useNeveForm<TextAndImageTaskInput>({
        title: activity?.title ?? ' ',
        description: activity?.description ?? '',
        durationInMins: activity?.durationInMins ?? null,
        content: activity?.content ?? '',
    });

    const [formDirty, setFormDirty] = React.useState<boolean>(false);

    useFormBlocker(formDirty, strings.courseEditorPage.unsavedChangesPrompt);

    const title = watch('title');
    const description = watch('description');
    const durationInMins = watch('durationInMins');
    const content = watch('content');

    React.useEffect(() => {
        const titleChanged = title !== activity.title;
        const descriptionChanged = description !== activity.description;
        const durationInMinsChanged = durationInMins != activity.durationInMins; // eslint-disable-line eqeqeq
        const contentChanged = content !== activity.content;
        const imageChanged = imageCardComponentData?.fileName !== activity.imageDocument?.filename;

        const resourcesChanged = resources != undefined && !arraysEqual(resources, activity.resources); // eslint-disable-line eqeqeq

        const changed =
            titleChanged ||
            descriptionChanged ||
            durationInMinsChanged ||
            contentChanged ||
            imageChanged ||
            resourcesChanged;

        setFormDirty(changed);
    }, [title, description, durationInMins, content, imageCardComponentData, resources]); // eslint-disable-line react-hooks/exhaustive-deps

    const { upload, uploading } = useUploadRequest();

    async function addResource(newResource: ResourcesInput) {
        setSaved(false);
        setResources([...(resources ?? []), newResource]);
        addLinkButtonRef.current?.focus();
    }

    function deleteResource(id: string | null, title: string, url: string, deleted: boolean): void {
        setSaved(false);

        if (id) {
            setResources(
                (resources ?? []).map((resource) => {
                    if (resource.id === id && deleted) {
                        return { title: resource.title, id: resource.id, url: resource.url, deleted };
                    } else {
                        return resource;
                    }
                }),
            );
        } else {
            setResources((resources ?? []).filter((resource) => !(resource.title === title && resource.url === url)));
        }

        if (saved) {
            setSaved(false);
        }

        addLinkButtonRef.current?.focus();
    }

    type SubmitFile = {
        filePath: string | null;
        fileName: string | null;
        id: string | null;
    };

    interface SubmitImageFile extends SubmitFile {
        imageAltText: string | null;
    }

    async function uploadImage(): Promise<SubmitImageFile | null> {
        if (localImageToBeUploaded) {
            const uploadResponse = await upload(localImageToBeUploaded);

            if (uploadResponse.errors && uploadResponse.errors.length > 0) {
                logError(uploadResponse.errors);
                updateErrors(uploadResponse.errors);
                throw new Error('Image upload failed');
            }

            if (!uploadResponse.value) {
                logError('Get presigned URL failed');
                updateErrors([{ field: 'global', code: 'get presigned URL failed' }]);
                throw new Error('Image upload failed');
            }

            return {
                filePath: uploadResponse.value.s3Key,
                fileName: uploadResponse.value.filename,
                id: null,
                imageAltText: localImageMetaData?.imageAltText ?? null,
            };
        } else if (providerImageToBeUploaded) {
            return {
                filePath: null,
                fileName: null,
                id: providerImageToBeUploaded.id,
                imageAltText: null,
            };
        } else if (activity.imageDocument) {
            return {
                filePath: null,
                fileName: null,
                id: activity.imageDocument.id,
                imageAltText: null,
            };
        } else {
            return null;
        }
    }

    async function onSaveActivity(formData: TextAndImageTaskInput) {
        setSaved(false);

        let imageData: SubmitImageFile | null = null;
        try {
            imageData = await uploadImage();
        } catch (error: any) {
            logError(error);
            return;
        }

        const response = await updateTask<any, Activity>({
            title: formData.title,
            description: formData.description,
            durationInMins: formData.durationInMins ? +formData.durationInMins : null,
            content: formData.content,
            image: imageData,
            resources,
            audioDocumentId: activity.audioDocument?.id,
        });
        const { errors } = response;

        if (errors) {
            logError(errors);
            updateErrors(errors);
            return;
        }

        reset(formData);
        await refetchActivity();
        await context.refetchCourse();
        setSaved(true);

        const contentHasChanged = activity.content !== formData.content;
        if (contentHasChanged) {
            setGenerateModalState('show');
        }
    }

    function updateErrors(apiErrors: APIError[]): void {
        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;
            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
            setError(field as keyof TaskInput, createFieldErrorFromAPIError(field, code));
        });
    }

    const removeImage = () => {
        setSaved(false);
        setLocalImageToBeUploaded(undefined);
        setProviderImageToBeUploaded(undefined);
        setImageCardComponentData(null);
        setActivity({ ...activity, imageDocument: null });
        selectImageButtonRef.current?.focus();
    };

    type FileCardComponentData = {
        fileName: string;
        fileDate: Date;
    };

    const titleMaxLength = 120;
    const descriptionMaxLength = 500;
    const contentMaxLength = 2000;

    const generateContentOptions: CheckboxOption[] = [
        {
            name: strings.generateContentModal.options.textToAudio,
            value: addAudio.toString(),
            inputProps: { onChange: () => setAddAudio(!addAudio) },
        },
    ];

    const generateContent = async () => {
        if (!addAudio) {
            setGenerateModalState('hide');
        } else {
            const response = await generateAudio({});
            setGenerateModalState(response.value === true ? 'success' : 'error');
        }
    };

    const closeGenerateContentModal = async () => {
        if (generateModalState === 'success') {
            await context.refetchCourse();
            await refetchActivity();
        }
        setGenerateModalState('hide');
    };

    return (
        <>
            {generateModalState !== 'hide' && (
                <GenerateContentModal
                    state={generateModalState}
                    close={closeGenerateContentModal}
                    generateContent={generateContent}
                    contentOptions={generateContentOptions}
                />
            )}
            <FormCardWithGaps onSubmit={handleSubmit(onSaveActivity)} id="activityForm">
                <div>
                    <TextField
                        fieldName="activityTitle"
                        labelText={strings.activityForm.titleInputLabel}
                        inputAria={strings.activityForm.titleInputAria}
                        inputProps={register('title', {
                            required: {
                                value: true,
                                message: requiredFieldErrorMessage(strings.activityForm.titleInputLabel),
                            },
                            maxLength: {
                                value: titleMaxLength,
                                message: createErrorMessage(
                                    strings.activityForm.titleInputLabel,
                                    fieldErrorCodes.maxLength,
                                ),
                            },
                            onChange: () => saved && setSaved(false),
                        })}
                        errorMessage={errors.title?.message}
                        required
                        autoFocus={!focusContent}
                    />
                    <TextAreaField
                        maxLength={descriptionMaxLength}
                        charactersLeft={descriptionMaxLength - watch('description').length}
                        fieldName={strings.activityForm.descriptionLabel}
                        aria={strings.activityForm.descriptionAria}
                        errorMessage={errors.description?.message}
                        inputProps={register('description', {
                            required: {
                                value: true,
                                message: requiredFieldErrorMessage(strings.activityForm.titleInputLabel),
                            },
                            maxLength: {
                                value: descriptionMaxLength,
                                message: createErrorMessage(
                                    strings.activityForm.descriptionLabel,
                                    fieldErrorCodes.maxLength,
                                ),
                            },
                            onChange: () => saved && setSaved(false),
                        })}
                        required
                    />
                    <TextField
                        fieldName="activityTaskDuration"
                        labelText={strings.activityForm.durationInputLabel}
                        inputAria={strings.activityForm.durationInputAria}
                        inputProps={register('durationInMins', {
                            validate: {
                                validateThreeCharacterLimit,
                            },
                            onChange: () => saved && setSaved(false),
                        })}
                        errorMessage={errors.durationInMins?.message}
                        inputType="number"
                        width="8rem"
                        flavourText={strings.activityForm.durationInputMins}
                    />
                </div>
                <ResourceGroup>
                    <TextAreaField
                        maxLength={contentMaxLength}
                        charactersLeft={contentMaxLength - content.length}
                        fieldName={strings.taskContentForm.contentLabel}
                        aria={strings.taskContentForm.contentAria}
                        errorMessage={errors.content?.message}
                        inputProps={register('content', {
                            required: {
                                value: true,
                                message: requiredFieldErrorMessage(strings.taskContentForm.contentLabel),
                            },
                            maxLength: {
                                value: contentMaxLength,
                                message: createErrorMessage(
                                    strings.taskContentForm.contentLabel,
                                    fieldErrorCodes.maxLength,
                                ),
                            },
                        })}
                        required
                        autoFocus={focusContent}
                    />
                </ResourceGroup>
                <ResourceGroup>
                    <Headline5>{strings.learningMaterialsForm.addImageTitle}</Headline5>
                    {imageCardComponentData && (
                        <ImageCard
                            fileName={imageCardComponentData.fileName}
                            uploadDate={imageCardComponentData.fileDate}
                            removeFile={removeImage}
                            disabled={uploading || updatingTask}
                        />
                    )}
                    <ImageSelectionComponent
                        setFileFromLocal={setLocalImageToBeUploaded}
                        setFileFromProvider={setProviderImageToBeUploaded}
                        setFileFromLocalMetaData={setLocalImageMetaData}
                        disableButton={uploading || updatingTask}
                        selectFileButtonRef={selectImageButtonRef}
                    />
                </ResourceGroup>
                <GeneratedContentSection task={activity} setTask={setActivity} />
                <ResourceGroup>
                    <Headline5>{strings.resourcesList.title}</Headline5>
                    <ResourcesList
                        resources={resources ?? []}
                        toggleModalOpen={() => {
                            setModalOpen(!modalOpen);
                            return saved && setSaved(false);
                        }}
                        deleteResource={deleteResource}
                        disabled={uploading || updatingTask}
                        addLinkButtonRef={addLinkButtonRef}
                    />
                </ResourceGroup>
                <ButtonAndNotificationContainer>
                    {saved && !formDirty && <SavedSuccessfullyNotification />}
                    <DeleteActivityButton setAlertOpen={setAlertOpen} />
                    <PrimaryButton
                        type="submit"
                        title={!uploading ? strings.activityForm.submitButton : strings.activityForm.uploadingFile}
                        aria={strings.activityForm.submitButtonAria}
                        disabled={uploading || updatingTask}
                        icon={uploading || updatingTask ? <Spinner /> : undefined}
                    />
                </ButtonAndNotificationContainer>
                {globalErrorMessage && <ErrorMessage id="errorMessage">{globalErrorMessage}</ErrorMessage>}
            </FormCardWithGaps>
            {alertOpen && (
                <DeleteActivityAlert
                    taskId={activity.id}
                    returnToCourseStructure={returnToCourseStructure}
                    setAlertOpen={setAlertOpen}
                />
            )}
            {modalOpen && (
                <LearningResourcesFormModal closeModal={() => setModalOpen(false)} addResource={addResource} />
            )}
        </>
    );
}

const ResourceGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const FormCardWithGaps = styled(FormCard)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    width: 100%;
`;
