import * as React from 'react';
import styled from 'styled-components';
import strings from '../../../strings/strings.json';
import { BodyRegular, Headline5 } from '../../../components/Elements';
import { Activity, ActivityType } from '../../../models/Activity';
import { breakpoints, sizes, theme } from '../../../theme';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import EditIcon from 'assets/icons/controls/EditIcon';
import ActivityTypeLabel from './ActivityTypeLabel';
import useWindowWidth from 'hooks/useWindowWidth';

type ActivityCardProps = {
    activity: Activity;
    showEditControls?: boolean;
    isDragging: boolean;
    editActivityClick?: (activityId: string) => void;
};

export default function ActivityCard({
    activity,
    showEditControls,
    isDragging,
    editActivityClick,
}: ActivityCardProps): JSX.Element {
    const { aboveTabletWidth, belowTabletWidth } = useWindowWidth();

    const generateSubtitle = () => {
        if (activity.type === ActivityType.QUESTIONNAIRE) {
            return (
                <Subtitle>
                    <BodyRegular>
                        {activity.questions.length} {strings.courseContentPage.questions}
                    </BodyRegular>
                </Subtitle>
            );
        }

        if (activity.type === ActivityType.LIVE_TRAINING) {
            return (
                <Subtitle>
                    <BodyRegular>
                        {activity.durationInMins} {strings.courseContentPage.mins}
                    </BodyRegular>
                </Subtitle>
            );
        }
    };

    return (
        <Grid $isDragging={isDragging}>
            <Title>
                <Headline5>{activity.title}</Headline5>
            </Title>
            {showEditControls && (
                <>
                    <ButtonContainer>
                        <SecondaryButton
                            title={strings.courseContentPage.editActivityButton}
                            aria={strings.courseContentPage.editActivityButtonAria}
                            onClick={editActivityClick}
                            icon={<EditIcon />}
                            compact={aboveTabletWidth}
                            fullwidth={belowTabletWidth}
                        />
                    </ButtonContainer>
                    <SubtitleContainer>
                        <ActivityTypeLabel activityType={activity.type} />
                        {generateSubtitle()}
                    </SubtitleContainer>
                </>
            )}
        </Grid>
    );
}

const Grid = styled.div<{ $isDragging: boolean }>`
    padding: ${sizes.spacingMd};
    border-radius: ${sizes.spacingSm};
    border: ${({ $isDragging }) => {
        return $isDragging ? `2px solid ${theme.primaryButtonBorderColour}` : `1px solid ${theme.cardBorder}`;
    }};
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: ${({ $isDragging }) => {
        return $isDragging ? theme.primaryButtonFocusBackgroundColour : 'none';
    }};

    display: grid;
    grid-template-areas:
        'title button'
        'subtitle .';
    grid-template-columns: 1fr;
    gap: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.md}) {
        grid-template-areas: 'title' 'subtitle' 'button';
        grid-template-columns: unset;
    }
`;

const Title = styled.div`
    grid-area: title;
`;

const ButtonContainer = styled.div`
    grid-area: button;
`;

const SubtitleContainer = styled.div`
    grid-area: subtitle;
    display: flex;
    align-items: center;
    gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        align-items: unset;
    }
`;

const Subtitle = styled.div`
    display: flex;
    flex-direction: column;
`;
