import styled from 'styled-components';
import { theme, sizes } from 'theme';

export const DarkFormHead = styled.div`
    background-color: ${theme.stepperBackgroundColour};
    min-height: 5rem;
`;

export const MainContainer = styled.div`
    max-width: 800px;
    padding-bottom: ${sizes.spacingLg};
    border: solid 1px ${theme.cardBorder};
    border-radius: ${sizes.spacingSm};
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: ${theme.cardBackground};
    overflow: hidden;
`;
