import styled from 'styled-components';
import { sizes, breakpoints } from 'theme';
import { Card } from '../FileCard';

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    flex: 65%;
`;

const ModuleListItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const ModulesList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
`;

const ItemCard = styled(Card)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${sizes.spacingSm};

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
        align-items: unset;
    }
`;

export { CardContent, ModuleListItem, ModulesList, ItemCard };
