import * as React from 'react';
import strings from '../../../strings/strings.json';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../../theme';
import { ButtonRowRight, Headline4 } from '../../../components/Elements';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { ErrorMessage } from '../../../components/forms/FormComponents';
import RadioFields, { RadioFieldOption } from '../../../components/forms/RadioFields';
import useNeveForm from 'components/forms/NeveForm';
import LiveTrainingCreateComponent from 'pages/edit-course/components/LiveTrainingCreateComponent';
import QuestionnaireSelectTypeForm from 'pages/edit-course/components/questionnaire/QuestionnaireSelectTypeForm';
import TaskCreateComponent from 'pages/edit-course/components/TaskCreateComponent';
import useFormBlocker from 'components/forms/useFormBlocker';
import { Module } from 'models/Module';

enum ActivityType {
    TASK = 0,
    LIVE_TRAINING = 1,
    QUESTIONNAIRE = 2,
}

type ActivityTypeOption = {
    activityType: ActivityType;
    name: string;
    description: string;
    aria: string;
    content: JSX.Element;
};

type ActivityTypeInput = {
    type: 0 | 1 | 2 | null;
};

type ActivityTypeSelectionPageContentProps = {
    module: Module;
    returnToModulesList: () => void;
};

export function ActivityTypeSelectionPageContent({
    module,
    returnToModulesList,
}: ActivityTypeSelectionPageContentProps): JSX.Element {
    const {
        register,
        handleSubmit,
        formState: { isDirty },
    } = useNeveForm<ActivityTypeInput>({
        type: null,
    });

    useFormBlocker(isDirty, strings.courseEditorPage.unsavedChangesPrompt);

    const [error, setError] = React.useState<string | null>(null);
    const [content, setContent] = React.useState<JSX.Element | null>(null);

    const ACTIVITY_TYPE_OPTIONS: ActivityTypeOption[] = [
        {
            activityType: ActivityType.TASK,
            name: strings.activityTypeSelectionPage.taskName,
            description: strings.activityTypeSelectionPage.taskDescription,
            aria: strings.activityTypeSelectionPage.taskAria,
            content: <TaskCreateComponent saveActivityClick={returnToModulesList} moduleId={module.id} />,
        },
        {
            activityType: ActivityType.LIVE_TRAINING,
            name: strings.activityTypeSelectionPage.liveTrainingName,
            description: strings.activityTypeSelectionPage.liveTrainingDescription,
            aria: strings.activityTypeSelectionPage.liveTrainingAria,
            content: <LiveTrainingCreateComponent saveActivityClick={returnToModulesList} moduleId={module.id} />,
        },
        {
            activityType: ActivityType.QUESTIONNAIRE,
            name: strings.activityTypeSelectionPage.questionnaireName,
            description: strings.activityTypeSelectionPage.questionnaireDescription,
            aria: strings.activityTypeSelectionPage.questionnaireAria,
            content: <QuestionnaireSelectTypeForm returnToModulesList={returnToModulesList} module={module} />,
        },
    ];

    const activityTypeOptions: RadioFieldOption[] = ACTIVITY_TYPE_OPTIONS.map((activityType) => {
        return {
            value: activityType.activityType,
            title: activityType.name,
            description: activityType.description,
            aria: activityType.aria,
            inputProps: register('type'),
            name: 'type',
        };
    });

    const submitActivityType = (formData: ActivityTypeInput) => {
        if (formData.type === null) {
            setError(strings.activityTypeSelectionPage.typeValidationError);
            return;
        }

        const selectedContent = ACTIVITY_TYPE_OPTIONS.find(
            (x) => x.activityType === (formData.type === null ? 0 : +formData?.type),
        )?.content;

        if (selectedContent) {
            setContent(selectedContent);
        }
    };

    return content ? (
        content
    ) : (
        <ActivityTypeFormContainer>
            <StepperContainer />
            <FormContainer onSubmit={handleSubmit(submitActivityType)}>
                <Headline4>{strings.activityTypeSelectionPage.instructionText}</Headline4>
                <RadioFields options={activityTypeOptions} />
                {error && <ErrorMessage id={`error-message`}>{error}</ErrorMessage>}
                <ButtonRowRight>
                    <PrimaryButton
                        type="submit"
                        title={strings.activityTypeSelectionPage.submitButton}
                        aria={strings.activityTypeSelectionPage.submitButtonAria}
                    />
                    <MarginTop />
                </ButtonRowRight>
            </FormContainer>
        </ActivityTypeFormContainer>
    );
}

const FormContainer = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    padding: ${sizes.spacingMd};
`;

const StepperContainer = styled.div`
    background-color: ${theme.stepperBackgroundColour};
    min-height: 5rem;
`;

const ActivityTypeFormContainer = styled.div`
    max-width: 800px;
    padding-bottom: ${sizes.spacingLg};
    border: solid 1px ${theme.cardBorder};
    border-radius: ${sizes.spacingSm};
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: ${theme.cardBackground};
    overflow: hidden;
`;

const MarginTop = styled.div`
    margin-top: 20px;
    @media (max-width: ${breakpoints.sm}) {
        margin-top: 0;
    }
`;
