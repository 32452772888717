import styled from 'styled-components';
import { sizes, theme } from '../../theme';
import { BodyRegular, BodySmall } from '../Elements';

export default function ProgressBar({
    label,
    max,
    current,
    reverseFractionAmount,
}: {
    label: string;
    max: number;
    current: number;
    reverseFractionAmount?: boolean;
}): JSX.Element {
    const calculatedWidthPercentage = (current / max) * 100;
    const calculatedCurrentAmount = reverseFractionAmount ? Math.max(max - current, 0) : current;

    return (
        <ProgressBarContainer>
            <ProgressBarBackground>
                <ProgressBarProgress $calculatedWidthPercentage={calculatedWidthPercentage} />
            </ProgressBarBackground>
            <TextBlock>
                <BodyRegular $bold>{label}</BodyRegular>
                <BodyRegular $bold>
                    {calculatedCurrentAmount}/{max}
                </BodyRegular>
            </TextBlock>
        </ProgressBarContainer>
    );
}

export function PercentageProgressBar({ label, percentage }: { label: string; percentage: number }): JSX.Element {
    return (
        <PercentageProgressBarContainer>
            <TextBlock>
                <BodySmallNoWrap>{label}</BodySmallNoWrap>
                <BodySmallNoWrap>{`${percentage}%`}</BodySmallNoWrap>
            </TextBlock>
            <ProgressBarBackgroundContainer>
                <ProgressBarBackground>
                    <ProgressBarProgress $calculatedWidthPercentage={percentage} />
                </ProgressBarBackground>
            </ProgressBarBackgroundContainer>
        </PercentageProgressBarContainer>
    );
}

const ProgressBarBackgroundContainer = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const TextBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${sizes.spacingSm};
`;

const BodySmallNoWrap = styled(BodySmall)`
    white-space: nowrap;
`;

const ProgressBarProgress = styled.div<{ $calculatedWidthPercentage: number }>`
    position: absolute;
    background: ${theme.progressBar.foreground};
    height: 20px;
    border-radius: 20px;
    min-width: 20px;
    max-width: 100%;
    width: ${({ $calculatedWidthPercentage }) => {
        return `${$calculatedWidthPercentage}%`;
    }};
`;

const ProgressBarBackground = styled.div`
    position: relative;
    background: ${theme.progressBar.background};
    width: 100%;
    height: 20px;
    border-radius: 20px;
`;

const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const PercentageProgressBarContainer = styled(ProgressBarContainer)`
    width: 100%;
`;
